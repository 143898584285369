import React from "react";
import PageRoutes from "./routes";
import {
  defaultTheme,
  ThemeProvider,
  Preflight,
  createGlobalStyle,
} from "@xstyled/styled-components";
import ReactGA from "react-ga4";
// Color scheme- https://coolors.co/11151c-212d40-56688a-7d4e57-d66853

const theme = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    black: "#11151C",
    "bg-main": "#fafafa",
    "input-border": "#364156",
    "input-txt": "#212D40",
    "txt-primary": "#364156",
    "txt-primary-light": "#56688A",
    "txt-warning": "#D66853",
    "txt-error": "#7D4E57",
    "txt-content-heading": "#364156",
    "btn-primary": "#364156",
    "btn-primary-hover": "#212D40",
    "btn-secondary": "#737373ff",
    "btn-secondary-hover": "#525252ff",
    "gray-200": "#e5e7ebff",
    "gray-400": "#9ca3afff",
    "gray-800": "#27272aff",
  },
};

const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: "Motiva Sans Light";
  src: url("/fonts/Motiva-Sans-Light.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Motiva Sans Bold";
  src: url("/fonts/Motiva-Sans-Bold.ttf") format("truetype");
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Motiva Sans Bold', serif;
}

body,
html,
a,
p,
span {
  font-family: 'Motiva Sans Light', sans-serif;
}

body {
  margin:0;
  padding:0;
  border: 0;
  outline: 0;
  overflow-x: hidden;
}

#root > footer {
  position: sticky;
  top: 100vh;
}

html, body, #root {
  height: 100%;
  background-color: ${(props) => props.bgColor};
}
`;

const App = () => {
  ReactGA.initialize("G-QVCQWRT5BS");

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle bgColor={theme.colors["bg-main"]} />
      <Preflight />
      <PageRoutes />
    </ThemeProvider>
  );
};

export default App;
