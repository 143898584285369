import React from "react";
import { useTheme } from '@xstyled/styled-components'
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  text-decoration: none;
  position: relative;
  font-size: 1.1rem;
  color: ${props => props.color};
  width: 100%;
  padding: ${props => props.p};
  margin-right: ${props => props.mr};
  font-weight: ${props => props.active ? "bold" : "unset"};
  &:hover {
    color: #0e5977;
  }
`;

const NavLink = ({ path, label, onClick, p, mr, active, ...rest }) => {
  const theme = useTheme()

  const handleClick = (event) => {
    if (onClick) {
      onClick(event);
    }
    if (!path) {
      event.preventDefault();
      onClick(event)
    }
  }

  return (
    <StyledLink
      key={`nav-item-link-${path}`}
      to={path}
      onClick={handleClick}
      color={theme.colors["gray-800"]}
      active={!!active ? 1 : 0}
      p={p}
      mr={mr}
      {...rest}>
      {label}
    </StyledLink>
  )
}

export default NavLink