import React from "react";
import Flex from "../../components/core/Flex";
import PageContent from "../../components/PageContent";
import ContentBlock from "../../components/ContentBlock";
import AboutContent from "../../content/Landing/AboutContent.json";
import WhyChooseUsContent from "../../content/Landing/WhyChooseUsContent.json";
import ServicesContent from "../../content/Landing/ServicesContent.json";
import HeroContent from "../../content/Landing/HeroContent.json";
import PageContainer from "../../components/PageContainer";
import { Helmet } from 'react-helmet-async';

const LandingPage = () => {
  return (
    <>
      <Helmet>
        <title>The Tech Narrative</title>
        <link rel="canonical" href="https://thetechnarrative.com" />
      </Helmet>
      <Flex backgroundColor={"gray-200"} width="100%" px={"4%"}>
        <PageContent>
          <ContentBlock {...HeroContent} />
        </PageContent>
      </Flex>
      <PageContainer>
        <PageContent>
          <Flex
            flexDirection="column"
            flex={1}
            gap={{ _: 14, lg: 64 }}
            mt={{ _: 4, lg: 36 }}
            mx={{ _: 4, lg: 0 }}
          >
            <ContentBlock {...AboutContent} />
            <ContentBlock {...ServicesContent} />
            <ContentBlock {...WhyChooseUsContent} />
          </Flex>
        </PageContent>
      </PageContainer>
    </>
  );
};

export default LandingPage;
