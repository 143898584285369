import { x } from '@xstyled/styled-components'

const P = ({ size, lightText, children, ...rest }) => {
    const getSize = () => {
        switch (size) {
            case "xs": return { fontSize: "0.8rem", lineHeight: "1.2rem", }
            case "sm": return { fontSize: "1rem", lineHeight: "1.5rem" }
            case "md": return { fontSize: { lg: "1.2rem", md: "1rem" }, lineHeight: "1.7rem" }
            case "lg": return { fontSize: { lg: "1.4rem", md: "1rem" }, lineHeight: "2.1rem" }
            default: return { fontSize: "1.2rem", lineHeight: "1.41" }
        }
    }

    return (<x.p color={lightText ? "txt-primary-light" : "txt-primary"}  {...rest} {...getSize()}>{children}</x.p>)
}

export default P