import React from "react";
import Article from "../../components/Article";
import PageContainer from "../../components/PageContainer";
import Profile from "../../components/Profile";
import Flex from "../../components/core/Flex";
import Heading from "../../components/core/Heading";
import { Helmet } from 'react-helmet-async';

const article = {
  heading: "About Us",
  content: [
    "At The Tech Narrative (TTN), we believe that every business has a unique story to tell through its technology. That's why we provide bespoke software consultancy services that help businesses translate their vision into reality.",
    "We are a UK based company located in Reading, England. If you're local, let's grab a coffee! If not, we can connect via video call! Either way, we want to talk about your technical vision for your company.",
    "Our team of experienced consultants work closely with our clients to understand their needs, goals, and challenges. We don't just write code – we listen, question, and collaborate to find the best solutions for our clients' businesses.",
    "At TTN, we pride ourselves on our ability to deliver high-quality software products that are not only functional but also beautiful, user-friendly, and scalable. We use the latest technologies, frameworks, and tools to ensure that our clients' software stays ahead of the curve.",
    "We also specialise in cloud migration and deployment, enabling organisations to take advantage of the benefits of cloud computing. Our team has extensive experience in designing, implementing, and managing cloud-based solutions for a variety of industries and use cases.",
    "At TTN, we're passionate about technology and its power to transform businesses. We're driven by a strong sense of purpose and a commitment to excellence. We believe that our success is measured by our clients' success, and we're proud to have helped many businesses achieve their goals through our software consultancy services.",
    "If you're looking for a reliable and experienced software consultancy partner, look no further than The Tech Narrative. Let us help you tell your tech story.",
  ],
  action: {
    text: "Get in touch",
    link: "/contact",
  },
};

const About = () => {
  return (
    <>
      <Helmet>
        <title>The Tech Narrative - About</title>
        <link rel="canonical" href="https://thetechnarrative.com/about" />
      </Helmet>
      <Flex backgroundColor={"gray-200"} width="100%" px={"5%"} py={4}>
        <Flex flexDirection="column" flex={1}>
          <Heading
            type="h1"
            size="lg"
            mb={8}
            color="txt-content-heading"
            mt={{ md: 12, _: 0 }}
          >
            Meet the team
          </Heading>
          <Flex
            flex={1}
            mt={4}
            mb={8}
            gap={8}
            flexDirection={{ lg: "row", _: "column" }}
          >
            <Profile
              name={"Oskar Figura"}
              title={"Co-founder & Principal Software Engineer"}
              img={"oskar-profile.jpeg"}
              description={`Navigating the worlds of fintech, healthcare, and logistics has taught me a lot about how people engage with technology.
                My mission is to make a difference and ensure that the technology we create transforms the everyday.
                Let's connect and dive into the exciting journey of elevating your tech story.`}
            />
            <Profile
              name={"Shreya Rijal"}
              title={"Co-founder & Principal Software Engineer"}
              img={"shreya-profile.jpeg"}
              description={`I believe in solutions that are simple, effective, and reliable. With experience in many industries such as insurance, defense, and aviation, 
                I have seen the impact good products have made to businesses. My technical specialisations are in React, React Native, and .NET. Outside work, 
                I am learning to play piano and I love socialising. I'm looking forward to speaking with you!`}
            />
          </Flex>
        </Flex>
      </Flex>
      <PageContainer>
        <Article
          heading={article.heading}
          content={article.content}
          action={article.action}
        />
      </PageContainer>
    </>
  );
};

export default About;
