import React from "react";
import { x } from '@xstyled/styled-components';
import NavLink from "../NavLink";

const NavItemLink = ({ icon, label, path, ...rest }) => {

    return (
        <>
            {icon && <x.i mr={2} className={icon} />}
            <NavLink path={path} label={label} {...rest} />
        </>
    );
};

export default NavItemLink;
