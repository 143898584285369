import { x } from "@xstyled/styled-components";
import ScrollToTopBtn from "../ScrollToTopBtn";

export const PageContent = ({ slim, children, ...rest }) => {
  return (
    <x.div
      py={4}
      pb={{ lg: 24, _: 12 }} // Footer size
      mx={{ lg: slim ? "8%" : "2%", _: "8%" }}
      {...rest}
    >
      <ScrollToTopBtn />
      {children}
    </x.div>
  );
};

export default PageContent;
