import { x } from '@xstyled/styled-components'

const Heading = ({ type = "h1", size, ...rest }) => {

    const getHeaderSize = () => {
        size = size === undefined ? type : size

        switch (size) {
            case "h1": return { lineHeight: "2rem", fontSize: "1.75rem" }
            case "h2": return { lineHeight: "1.75rem", fontSize: "1.5rem" }
            case "h3": return { lineHeight: "1.5rem", fontSize: "1.25rem" }
            case "h4": return { lineHeight: "1.25rem", fontSize: "1rem" }
            case "hs": return { lineHeight: "1rem", fontSize: "0.875rem", letterSpacing: "0.06rem", textTransform: "uppercase" }
            case "lg": return { lineHeight: { lg: "3.8rem", md: "3.85rem", _: "2.5rem" }, fontSize: { lg: "3rem", md: "2.5rem", _: "1.5rem" } }
            default: return { lineHeight: "2rem", fontSize: "1.75rem" }
        }
    }

    const getHeader = () => {
        switch (type) {
            case "h1": return <x.h1 color="txt-primary" fontWeight="bold"  {...getHeaderSize()} {...rest} />
            case "h2": return <x.h2 color="txt-primary" fontWeight="bold"  {...getHeaderSize()} {...rest} />
            case "h3": return <x.h3 color="txt-primary" fontWeight="bold"  {...getHeaderSize()} {...rest} />
            case "h4": return <x.h4 color="txt-primary" fontWeight="bold" {...getHeaderSize()} {...rest} />
            case "hs": return <x.span color="txt-primary" fontWeight="bold"  {...getHeaderSize()} {...rest} />
            default: return <x.h1 color="txt-primary" fontWeight="bold"  {...getHeaderSize()} {...rest} />
        }
    }

    return (getHeader())
}

export default Heading