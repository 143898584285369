export const getLinkType = (link) => {
    const internal = /^\/(?!\/)/.test(link);
    const external = /^(https?:)?\/\//.test(link);
    const scroll = /^#/.test(link);
    if (scroll) {
        return 'scroll';
    } else if (internal) {
        return 'internal';
    } else if (external) {
        return 'external';
    } else {
        return 'unknown';
    }
}