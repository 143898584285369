import React from "react";
import Heading from "../../components/core/Heading";
import Flex from "../../components/core/Flex";
import PageContent from "../../components/PageContent";
import Button from "../../components/core/Button";
import P from "../core/P";
import { generateArticleKey } from "../../helpers/Blog";

const Article = ({ heading, content, action }) => {
    return (
        <PageContent slim>
            <Flex flexDirection="column" flex={1}>
                <Heading type="h1" size="lg" mb={4} color="txt-content-heading" mt={{ md: 12, _: 0 }}>
                    {heading}
                </Heading>
                {
                    Array.isArray(content) &&
                    content.map(v => {
                        return typeof v === 'object' && !!v.heading
                            ? <Heading key={generateArticleKey(heading, v.text)} type={v.heading} color="txt-content-heading" mt={{ md: 12, _: 0 }}>{v.text}</Heading>
                            : <P size="md" mt={4} key={generateArticleKey(heading, typeof v === 'object' ? v.text : v)}>{ typeof v === 'object' ? v.text : v}</P>
                    })
                }
                {
                    !!action &&
                    <Flex flex={1} mt={16} mb={8} flexDirection="row" alignItems="center" justifyContent="center">
                        <Button link={action.link}>{action.text}</Button>
                    </Flex>
                }
            </Flex>
        </PageContent>
    );
};

export default Article