import Flex from "../Flex"
import Strong from '../Strong'
import Span from "../Span"

const Field = ({ label, htmlFor, children, errorMsg, ...rest }) => {
    return (
        <Flex flexDirection="column" flex={1} my={4} {...rest}>
            {!!label && <Strong htmlFor={htmlFor} mb={4}>{label}</Strong>}
            {children}
            {!!errorMsg && <Span size="xs" mt={2} color="txt-warning">{errorMsg}</Span>}
        </Flex>
    )
}

export default Field