import React from "react";
import Article from "../../components/Article";
import PageContainer from "../../components/PageContainer";
import { Helmet } from 'react-helmet-async';

const article = {
  heading: "Privacy Policy",
  content: [
    {
      text: "This Privacy Policy outlines how we collect, use, and protect your personal information. By submitting your information, you agree to the terms of this policy.",
    },
    { text: "Information We Collect", heading: "h3" },
    {
      text: "We may collect personal information such as your name, email address, job title, company name, and phone number.",
    },
    { text: "Use of Information", heading: "h3" },
    {
      text: "We may use the information you provide to contact you about our products or services, to respond to your inquiries or requests, or to provide you with other information we believe may be of interest to you.",
    },
    { text: "Disclosure of Information", heading: "h3" },
    {
      text: "We may disclose your personal information to third-party service providers who assist us in providing our products or services to you. We may also disclose your personal information if required by law or in connection with a legal claim or proceeding.",
    },
    { text: "Security of Information", heading: "h3" },
    {
      text: "We take reasonable measures to protect your personal information from unauthorised access, use, or disclosure. However, we cannot guarantee the security of your information.",
    },
    { text: "Retention of Information", heading: "h3" },
    {
      text: "We will retain your personal information for as long as necessary to fulfill the purposes outlined in this policy, unless a longer retention period is required or permitted by law.",
    },
    { text: "Your Rights", heading: "h3" },
    {
      text: "You have the right to access, correct, or delete your personal information at any time. You may also opt-out of receiving marketing communications from us.",
    },
    { text: "Changes to This Policy", heading: "h3" },
    {
      text: "We reserve the right to modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised policy.",
    },
    { text: "Contact Us", heading: "h3" },
    "If you have any questions or concerns about our privacy practices, please contact us",
  ],
  action: {
    text: "Get in touch",
    link: "/contact",
  },
};

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>The Tech Narrative - Privacy Policy</title>
        <link rel="canonical" href="https://thetechnarrative.com/privacy-policy" />
      </Helmet>
      <PageContainer>
        <Article
          heading={article.heading}
          content={article.content}
          action={article.action}
        />
      </PageContainer>
    </>
  );
};

export default PrivacyPolicy;
