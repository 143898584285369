import React from "react";
import Button from "../../../../core/Button";
import { Link } from "react-router-dom";

const NavItemBtn = ({ path, label }) => {
  return (
    <Link key={path || "#"} to={path || "#"} px={4}>
      <Button borderRadius="lg">{label}</Button>
    </Link>
  );
};

export default NavItemBtn;
