import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { x } from "@xstyled/styled-components";
import Logo from "../../Logo";
import NavItem from "../NavItem";
import Burger from "../Burger";
import { useDown } from "../../../helpers/Media";
import DropdownNavItem from "../DropdownNavItem";
import Flex from "../../core/Flex";

const useClickOutside = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

const NavBar = ({ menuLinks, user, onAccountClick, ...rest }) => {
  const [activePage, setActivePage] = useState()
  const [showMenu, setShowMenu] = useState({});
  const isSmallScreen = useDown();
  const menuRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (activePage === '/' || !activePage) {
      setActivePage(location.pathname)
    }
  }, [location.pathname]);

  useClickOutside(menuRef, () => {
    Object.keys(showMenu).forEach((index) => {
      setShowMenu((prevShowMenu) => ({ ...prevShowMenu, [index]: false }));
    });
  });

  const handleMenuToggle = () => {
    setShowMenu((prevShowMenu) => ({
      ...prevShowMenu,
      main: !prevShowMenu.main,
    }));
  };

  const handleSubMenuToggle = (index) => {
    setShowMenu((prevShowMenu) => ({
      ...prevShowMenu,
      [index]: !prevShowMenu[index],
    }));
  };

  const renderMenuLinks = (links) => {
    return links.map((link, index) => {
      const isLast = links.length === index + 1;
      if (Array.isArray(link.children)) {
        return (
          <DropdownNavItem
            key={`nav-item-drop${link.path}`}
            label={link.label}
            links={link.children}
            isOpen={showMenu[index]}
            onClick={() => handleSubMenuToggle(index)}
            onSubLinkClick={() => {
              setShowMenu({});
            }}
            isLast={isLast}
          />
        );
      } else {
        return (
          <NavItem
            key={`nav-item-${link.path}`}
            label={link.label}
            path={link.path}
            btnStyle={isSmallScreen ? false : link.btnStyle}
            active={activePage === link.path}
            onClick={() => {
              setActivePage(link.path)
              setShowMenu({});
            }}
          />
        );
      }
    });
  };

  return (
    <x.nav
      display="flex"
      alignItems={isSmallScreen ? "flex-start" : "center"}
      justifyContent={isSmallScreen ? "center" : "space-between"}
      position="sticky"
      top="0"
      zIndex="1"
      py="1.5rem"
      flexDirection={isSmallScreen ? "column" : "row"}
      flex={1}
      {...rest}
    >
      <Flex
        flexDirection="row"
        flex={1}
        alignItems="center"
        w="100%"
        px={isSmallScreen ? 8 : 0}
      >
        <Link to="/" onClick={() => { setActivePage('/'); setShowMenu({}); }}>
          <Logo />
        </Link>
        {isSmallScreen && (
          <Flex justifyContent="flex-end" flex={1}>
            <Burger
              active={showMenu.main}
              onClick={(e) => {
                handleMenuToggle();
                e.stopPropagation();
              }}
            />
          </Flex>
        )}
      </Flex>

      {isSmallScreen ? (
        <>
          {showMenu.main && (
            <x.div w="100%" p="2rem" ref={menuRef} mb={4}>
              {renderMenuLinks(menuLinks)}
            </x.div>
          )}
        </>
      ) : (
        <x.div display="flex" alignItems="center" ref={menuRef}>
          {renderMenuLinks(menuLinks)}
        </x.div>
      )}
    </x.nav>
  );
};

export default NavBar;
