import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Spinner from "../core/Spinner";
import Flex from "../core/Flex";
import Header from "../Header";
import Footer from "../Footer";

const AuthLayout = () => {
  const user = "test-user";
  if (user !== null) {
    const isAuthenticated = true;
    return isAuthenticated ? (
      <>
        <Header />
        <Outlet />
        <Footer />
      </>
    ) : (
      <Flex h="100%" alignItems="center" justifyContent="center" flex={1}>
        <Spinner />
      </Flex>
    );
  }
  return <Navigate to={"/login"} replace />;
};

export default AuthLayout;
