import { forwardRef } from 'react';
import { x } from '@xstyled/styled-components';

const Input = forwardRef((props, ref) => {
    return (
        <x.input
            ref={ref}
            p={4}
            fontSize="1rem"
            lineHeight="1.5rem"
            borderStyle="solid"
            borderWidth="1px"
            borderColor="input-border"
            borderRadius="md"
            outlineColor="input-border"
            color="input-txt"
            w="100%"
            boxSizing="border-box"
            {...props}
        />
    );
});

export default Input;