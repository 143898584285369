import React, { useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import Input from "../../components/core/Input";
import PageContent from "../../components/PageContent";
import Field from "../../components/core/Field";
import Button from "../../components/core/Button";
import Heading from "../../components/core/Heading";
import Flex from "../../components/core/Flex";
import TextArea from "../../components/core/TextArea";
import P from "../../components/core/P";
import Span from "../../components/core/Span";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import PageContainer from "../../components/PageContainer";
import { Helmet } from 'react-helmet-async';

const Contact = () => {
  const [loading, setLoading] = useState();
  const [_, setDataErrors] = useState();
  const [formSubmitted, setFormSubmitted] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const captchaRef = useRef(null);

  const getCaptchaToken = async () => {
    const token = await captchaRef.current.executeAsync();
    captchaRef.current.reset();
    return token;
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const token = await getCaptchaToken();
      let payload = { ...data, "g-recaptcha-response": token };

      emailjs
        .send(
          "service_cal1zhp",
          "template_8nhyuvb",
          payload,
          "ccDkltgOf796--F0h"
        )
        .catch((_) => {
          setDataErrors(true);
        })
        .finally((_) => {
          setFormSubmitted(true);
          setLoading(false);
        });
    } catch (_) {
      setDataErrors(true);
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>The Tech Narrative - Contact</title>
        <link rel="canonical" href="https://thetechnarrative.com/contact" />
      </Helmet>
      <PageContainer>
        <PageContent>
          <Flex
            flexDirection={{ md: "row", _: "column" }}
            flex={1}
            mt={{ md: 20, _: 4 }}
            gap={12}
          >
            <Flex
              flexDirection="column"
              flex={1}
              alignItems="flex-start"
              justifyContent="center"
            >
              <Heading type="h1" size="lg" mb={4} color="txt-content-heading">
                {formSubmitted
                  ? "Thank you for getting in touch"
                  : "Contact Us"}
              </Heading>
              <P size="lg" pr={{ md: "10%", _: "0" }}>
                {formSubmitted
                  ? "We appreciate your message and will get back to you as soon as possible"
                  : "Get in touch with us to schedule a consultation and learn more about how we can help your business grow"}
              </P>
            </Flex>
            <Flex flexDirection="column" flex={1}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Field
                  label="Name"
                  htmlFor="name"
                  errorMsg={errors.name?.message}
                >
                  <Input
                    disabled={formSubmitted}
                    type="text"
                    id="name"
                    {...register("name", { required: "Name is required" })}
                    placeholder="John Smith"
                  />
                </Field>
                <Field
                  label="Telephone"
                  htmlFor="mobile"
                  errorMsg={errors.mobile?.message}
                >
                  <Input
                    disabled={formSubmitted}
                    id="mobile"
                    {...register("mobile", {
                      pattern: {
                        value:
                          /^(?:\+(?:\d\s?){1,3})?(?:\(\d{1,4}\)|\d{1,4})[\s-]?\d{3,}[\s-]?\d{3,}(?:[\s-]?\d{1,4})?$/,
                        message: "Please enter a valid telephone number",
                      },
                    })}
                    placeholder="07755532123"
                  />
                </Field>
                <Field
                  label="Email"
                  htmlFor="email"
                  errorMsg={errors.email?.message}
                >
                  <Input
                    disabled={formSubmitted}
                    id="email"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Please enter a valid email",
                      },
                    })}
                    placeholder="johnsmith@email.com"
                  />
                </Field>
                <Field
                  label="Message"
                  htmlFor="message"
                  errorMsg={errors.message?.message}
                >
                  <TextArea
                    disabled={formSubmitted}
                    type="text"
                    rows={8}
                    id="message"
                    {...register("message", {
                      required: "Message is required",
                      minLength: {
                        value: 1,
                        message: "Message must be at least 10 characters long",
                      },
                      maxLength: {
                        value: 1000,
                        message: "Message length cannot exceed 1000 characters",
                      },
                    })}
                    placeholder="Our in-house legacy application needs to be rebuilt with modern practices and have cross-platform capabilities. Can you help?"
                  />
                </Field>
                {!formSubmitted && (
                  <Field
                    htmlFor="captchaToken"
                    errorMsg={errors.captchaToken?.message}
                    mb={4}
                  >
                    <Controller
                      control={control}
                      name="captchaToken"
                      render={({ field: { onChange } }) => (
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_SITE_KEY}
                          ref={captchaRef}
                          size="invisible"
                          onChange={onChange}
                          onErrored={() => setDataErrors(true)}
                        />
                      )}
                    />
                  </Field>
                )}
                {formSubmitted ? (
                  <Span size="md">Message Sent!</Span>
                ) : (
                  <Button
                    disabled={loading}
                    loading={loading}
                    loadingText="Sending..."
                    onClick={() => getCaptchaToken()}
                  >
                    Submit
                  </Button>
                )}
              </form>
            </Flex>
          </Flex>
        </PageContent>
      </PageContainer>
    </>
  );
};

export default Contact;
