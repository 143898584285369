import { forwardRef } from 'react';
import { x } from '@xstyled/styled-components'

const TextArea = forwardRef(({ ...rest }, ref) => {
    return (
        <x.textarea
            p={4}
            borderStyle="solid"
            borderWidth="1px"
            borderColor="input-border"
            borderRadius="md"
            outlineColor="input-border"
            color="input-txt"
            w="100%"
            boxSizing="border-box"
            resize="vertical"
            ref={ref}
            {...rest}
        />
    )
})

export default TextArea
