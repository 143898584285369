import React from "react";
import { x, useTheme } from '@xstyled/styled-components'
import styled from "styled-components";
import Flex from "../../core/Flex";
import NavItem from "../NavItem";
import { useDown } from "../../../helpers/Media";

const DropdownContent = styled('div')`
  position: absolute;
  top: 100%;
  left: 0;
  display: "block";
  min-width: 160px;
  width: 100%;
  z-index: 1;
  background-color: ${(props) => (props.color)};
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 8px;
  margin-top: 10px;
`;

const DropdownNavItem = ({ label, links, isLast, isOpen, onClick, onSubLinkClick }) => {
  const isSmallScreen = useDown()
  const theme = useTheme()

  const renderLinks = () => {
    return (
      links.map((link, i) => (
        !!link && <NavItem key={`nav-item-${link.path}`} path={link.path} label={link.label} onClick={onSubLinkClick} />
      ))
    )
  }

  return (
    <x.div position="relative" display="inline-block" mt="auto" mb={(isLast || !isSmallScreen || !isOpen) ? "auto" : 2} w={isSmallScreen ? "100%" : "auto"}>
      <NavItem label={label} onClick={onClick}/>
      {
        !!isOpen && (
          isSmallScreen
            ? <Flex pl={8} flexDirection="column">{renderLinks()}</Flex>
            : <DropdownContent color={theme.colors["true-gray-50"]}>{renderLinks()}</DropdownContent>
        )
      }
    </x.div>
  );
};

export default DropdownNavItem;
