import React from "react";
import NavItemLink from "./components/NavItemLink";
import NavItemBtn from "./components/NavItemBtn";
import { x } from "@xstyled/styled-components";
import { useDown } from "../../../helpers/Media";
import { scrollTo } from "../../../helpers/Window";
import { getLinkType } from "../../../helpers/Router";

const NavItem = ({ onClick, btnStyle = false, path, ...rest }) => {
  const isSmallScreen = useDown();
  const padding = `0.5rem 1rem 0.5rem 1rem `;

  const handleClick = (e) => {
    const linkType = getLinkType(path);
    switch (linkType) {
      case "scroll":
        scrollTo(path);
        break;
      default:
        !!onClick && onClick(e);
    }
  };

  return (
    <x.div
      display="flex"
      alignItems="center"
      fontWeight="normal"
      color="inherit"
      cursor="pointer"
      transition="all 0.2s"
      bg={!btnStyle ? { hover: "gray-200" } : {}}
      borderRadius="sm"
      mx={isSmallScreen ? 0 : 2}
      onClick={onClick}
    >
      {btnStyle && !isSmallScreen ? (
        <NavItemBtn path={path} onClick={handleClick} {...rest} />
      ) : (
        <NavItemLink p={padding} path={path} onClick={handleClick} {...rest} />
      )}
    </x.div>
  );
};

export default NavItem;
