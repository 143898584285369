import React, { forwardRef } from "react";
import { x } from '@xstyled/styled-components'

const Flex = forwardRef(({ children, ...rest }, ref) => {
  return (
    <x.div
      display="flex"
      ref={ref}
      {...rest}
    >
      {children}
    </x.div>
  )
})

export default Flex;