import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import NotFoundView from './routes/404'
import LandingPage from './routes/landing'
import AuthLayout from './components/AuthLayout'
import About from './routes/about'
import Services from './routes/services'
import Contact from './routes/contact'
import ScrollToTop from './components/ScrollToTop'
import PrivacyPolicy from './routes/privacy'

const PageRoutes = (props) => {
    return (
        <BrowserRouter>
            <ScrollToTop>
                <Routes>
                    <Route element={<AuthLayout />}>
                        <Route path="/" element={<LandingPage />} />
                        <Route path="/services" element={<Services />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/contact" element={<Contact />} />
                    </Route>
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path='/*' element={<NotFoundView />} />
                </Routes>
            </ScrollToTop>
        </BrowserRouter>
    )
}

export default PageRoutes