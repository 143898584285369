import { x } from "@xstyled/styled-components";

export const PageContainer = ({ wide, children, ...rest }) => {
  return (
    <x.div
      position="relative"
      container={{ md: true }}
      px={{ lg: "6%", _: "0%" }}
      mx="auto"
      {...rest}
    >
      {children}
    </x.div>
  );
};

export default PageContainer;
