import React from 'react';
import Flex from '../core/Flex';
import Span from '../core/Span';
import PageContainer from '../PageContainer';
import { x } from '@xstyled/styled-components';

const Footer = ({ ...rest }) => {
  const currentYear = new Date().getFullYear();

  return (
    <x.footer>
      <x.div
        position="relative"
        h={24}
        w="100%"
        mx="auto"
        {...rest}
      >
        <PageContainer>
          <Flex flexDirection="column" flex={1} w="100%" alignItems="center" pt={8} my="auto">
            <Span lightText textAlign="center" size="xs">
              {`Copyright © ${currentYear} The Tech Narrative Ltd. All rights reserved | Located in Reading, UK`}
            </Span>
          </Flex>
        </PageContainer>
      </x.div>
    </x.footer>
  );
};

export default Footer;
