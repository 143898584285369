import { useLocation } from 'react-router-dom';
import { useLayoutEffect, useEffect } from 'react';
import ReactGA from "react-ga4";

const ScrollToTop = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
        if (process.env.NODE_ENV === "production") {
            ReactGA.send({ hitType: "pageview", page: location.pathname, title: location.pathname });
        }
    }, [location]);


    return children
}

export default ScrollToTop