import React from "react";
import Heading from "../../components/core/Heading";
import Flex from "../../components/core/Flex";

function NotFoundView() {
  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        my="auto"
        h="100vh"
        flex={1}
      >
        <Heading as="h2" mt="4" textAlign="center" fontSize="3xl">
          Oops! Page Not Found
        </Heading>
        <Flex mt="6" justifyContent="center" textAlign="center">
          <Heading as="h3" fontSize="xl" fontWeight="light">
            The page you are looking for might have been removed, had its name
            changed, or is temporarily unavailable.
          </Heading>
        </Flex>
      </Flex>
    </>
  );
}

export default NotFoundView;
