import React from "react";
import Flex from "../../components/core/Flex";
import PageContent from "../../components/PageContent";
import ContentBlock from "../../components/ContentBlock";
import { generateArticleKey } from "../../helpers/Blog";
import PageContainer from "../../components/PageContainer";
import { Helmet } from 'react-helmet-async';

const servicesContent = [
  {
    heading: "Bespoke Software Development",
    content: [
      "We provide end-to-end software development services, from requirements gathering to development, testing, and deployment. Our experienced team leverages the latest technologies and frameworks to build robust and scalable bespoke software solutions that meet your unique business needs.",
    ],
    icon: "app.svg",
    position: "left",
  },
  {
    heading: "IT Consulting and Strategy",
    content: [
      "We offer expert IT consulting services to help businesses develop and execute effective IT strategies. Our team has a wealth of knowledge and experience across a range of industries and can help you identify areas for improvement, streamline your operations, and achieve your business goals.",
    ],
    icon: "consulting.svg",
    position: "left",
  },
  {
    heading: "Cloud Migration and Deployment",
    content: [
      "We help organisations migrate their applications and data to the cloud, enabling them to take advantage of the benefits of cloud computing such as scalability, flexibility, and cost savings. Our team has extensive experience in designing, implementing, and managing cloud-based solutions for a variety of industries and use cases.",
    ],
    icon: "going-up.svg",
    position: "left",
  },

  {
    heading: "DevOps and Agile Software Development",
    content: [
      "We specialise in agile software development methodologies and DevOps practices to ensure that our clients receive the highest quality bespoke software solutions. Our team can work closely with your internal IT staff to improve your software development processes and accelerate your time-to-market.",
    ],
    icon: "product-lifecycle.svg",
    position: "left",
  },
  {
    heading: "Digital Transformation Services",
    content: [
      "We help organisations transform their operations, processes, and customer experience through digital technologies. Our team can help you identify areas of improvement, design and implement digital solutions, and provide ongoing support and maintenance. We work closely with our clients to ensure that their digital transformation journey is smooth and successful.",
    ],
    icon: "flowers.svg",
    position: "left",
  },
];

const Services = () => {
  return (
    <>
      <Helmet>
        <title>The Tech Narrative - Services</title>
        <link rel="canonical" href="https://thetechnarrative.com/services" />
      </Helmet>
      <PageContainer>
        <PageContent>
          <Flex
            flexDirection="column"
            flex={1}
            gap={{ _: 14, lg: 64 }}
            mt={{ _: 4, lg: 36 }}
            mx={{ _: 4, lg: 0 }}
          >
            {servicesContent.map((v) => (
              <ContentBlock
                key={generateArticleKey(v.heading, v.content[0])}
                {...v}
              />
            ))}
          </Flex>
        </PageContent>
      </PageContainer>
    </>
  );
};

export default Services;
