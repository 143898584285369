import Flex from "../core/Flex";
import Heading from "../core/Heading";
import P from "../core/P";
import Img from "../core/Img";

const Profile = ({ name, title, description, img }) => {
  return (
    <Flex
      flexDirection={{ lg: "row", md: "row", _: "column" }}
      gap={4}
      backgroundColor={"btn-primary"}
      p={4}
      borderRadius={8}
      flex={"1 1 0"}
    >
      <Img
        alt={img}
        w={{ lg: "auto", md: "auto", _: "100%" }}
        h={{ lg: "250px", md: "250px", _: "auto" }}
        maxWidth={"400px"}
        src={`/img/${img}`}
        alignSelf="center"
        borderRadius={8}
      ></Img>
      <Flex flexDirection={"column"} gap={4}>
        <Heading color={"bg-main"} type={"h2"}>
          {name}
        </Heading>
        <Heading color={"bg-main"} type={"h3"}>
          {title}
        </Heading>
        <P color={"bg-main"} alignSelf="center">
          {description}
        </P>
      </Flex>
    </Flex>
  );
};

export default Profile;
