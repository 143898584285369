import { x } from '@xstyled/styled-components'

const Span = ({ lightText, size, children, wordBreak, ...rest }) => {
    const getWordBreakProps = () => {
        return !!wordBreak ? { "wordBreak": wordBreak } : {}
    }

    const getSize = () => {
        switch (size) {
            case "xs": return { fontSize: "0.8rem", lineHeight: "1.2rem", }
            case "sm": return { fontSize: "1rem", lineHeight: "1.5rem" }
            case "md": return { fontSize: { lg: "1.2rem", md: "1rem" }, lineHeight: "1.7rem" }
            case "lg": return { fontSize: { lg: "1.4rem", md: "1rem" }, lineHeight: "2.1rem" }
            default: return { fontSize: "1rem", lineHeight: "1.2" }
        }
    }

    return (
        <x.span style={{ ...getWordBreakProps() }} {...getSize()} color={lightText ? "txt-primary-light" : "txt-primary"} {...rest}>{children}</x.span>
    )
}

export default Span