import { useState, useEffect } from "react";
import { x } from "@xstyled/styled-components";
import SvgIcon from "../core/SvgIcon";
import { getScroll } from "../../helpers/Window";

const ScrollToTopBtn = () => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = (event) => {
    const offsetFromTop = getScroll(event.target, true);

    if (!showScroll && offsetFromTop > 350) {
      setShowScroll(true);
    } else if (offsetFromTop <= 350) {
      setShowScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollUp = () => {
    const element = document.getElementById("root");
    element.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  return (
    <x.div
      display="flex"
      alignItems="center"
      justifyContent="center"
      p={2}
      position="fixed"
      right={30}
      bottom={30}
      zIndex="10"
      cursor="pointer"
      bg="#e5e7ebff"
      textAlign="center"
      borderRadius={4}
      transition="all 0.3s ease-in-out"
      visibility={showScroll ? "visible" : "hidden"}
      opacity={showScroll ? "1" : "0"}
      flexDirection="column"
      onClick={scrollUp}
    >
      <SvgIcon src="scroll-top.svg" width="20px" height="20px" />
    </x.div>
  );
};

export default ScrollToTopBtn;
