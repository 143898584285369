import NavBar from "../Nav/NavBar";
import { x } from "@xstyled/styled-components";
import PageContainer from "../PageContainer";
import Flex from "../core/Flex";

const menuLinks = [
  { label: "Services", path: "/services" },
  { label: "About Us", path: "/about" },
  { label: "Contact Us", path: "/contact", btnStyle: true },
];

const Header = () => {
  return (
    <x.header backgroundColor={"gray-200"}>
      <Flex backgroundColor={"gray-200"} px={{ lg: "4%", _: "0%" }}>
        <NavBar menuLinks={menuLinks} />
      </Flex>
    </x.header>
  );
};

export default Header;
