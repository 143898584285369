import SvgIcon from "../core/SvgIcon";
import Button from "../core/Button";
import Flex from "../core/Flex";
import Heading from "../core/Heading";
import P from "../core/P";
import { x } from '@xstyled/styled-components';
import { generateArticleKey } from "../../helpers/Blog";

const ContentBlock = ({
    heading,
    subheading,
    content,
    button,
    icon,
    id,
    position = 'center',
    children,
}) => {
    const contentContainer = (
        <Flex flexDirection="column" flex={1} textAlign={position === "center" ? "center" : undefined}>
            <x.div mt="3rem" >
                <Heading type="h1" size="lg" mb={8} color="txt-content-heading">{heading}</Heading>
                {!!subheading && <Heading type="h2" mb={8} color="txt-content-heading">{subheading}</Heading>}
                {Array.isArray(content) && content.map(v => <P size="md" key={generateArticleKey(heading, v)} mt={4}>{v}</P>)}
                <Flex flexDirection="row" gap="1rem" alignItems="center" justifyContent={position === "center" ? "center" : undefined} mt={8}>
                    {typeof button === "object" &&
                        button.map((btn, id) => {
                            return (
                                <Button
                                    key={id}
                                    color={btn.color || "white"}
                                    fixedWidth={true}
                                    link={btn.link}
                                    borderRadius="lg"
                                    boxShadow="lg"
                                >
                                    {btn.text}
                                </Button>
                            );
                        })}
                </Flex>
            </x.div>
        </Flex>
    );

    return (
        <Flex
            flexDirection={{ md: "row", _: "column" }}
            justify="space-between"
            alignItems="center"
            id={id}
            flex={1}
            w="100%"
        >
            {position === 'left' && (
                <Flex flexDirection={{ md: "row", _: "column" }} gap={{ lg: 12, _: 4 }} alignItems="center">
                    <Flex flexDirection="column" flex={1} w="100%">
                        {contentContainer}
                    </Flex>
                    <Flex flexDirection="column" flex={1} w="100%">
                        <SvgIcon src={icon} w="100%" h="100%" />
                    </Flex>
                </Flex>
            )}

            {position === 'center' && (
                <Flex flexDirection="column" alignItems="center" justifyContent="center">
                    <Flex flexDirection="column" flex={1} w="100%">
                        {contentContainer}
                    </Flex>
                    {children && (
                        <Flex flexDirection="column" flex={1} w="100%">
                            {children}
                        </Flex>
                    )}
                </Flex>
            )}

            {position === 'right' && (
                <Flex flexDirection={{ md: "row", _: "column-reverse" }} gap={{ lg: 12, _: 4 }} alignItems="center">
                    <Flex flexDirection="column" flex={1} w="100%" >
                        <SvgIcon src={icon} w="100%" h="100%" />
                    </Flex>
                    <Flex flexDirection="column" flex={1} w="100%" ml={{ _: 0, lg: 4 }}>
                        {contentContainer}
                    </Flex>
                </Flex>
            )}
        </Flex>
    );
};

export default ContentBlock;