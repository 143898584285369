import { x } from '@xstyled/styled-components'
import { useNavigate } from 'react-router-dom';
import { getLinkType } from '../../../helpers/Router';
import { scrollTo } from '../../../helpers/Window';

const Button = ({ onClick, link, loading, loadingText = "Loading...", variant = "primary", children, ...rest }) => {
  const navigate = useNavigate();

  const handleClick = (e, link) => {
    const linkType = getLinkType(link);
    switch (linkType) {
      case 'scroll':
        scrollTo(link)
        break;
      case 'internal':
        navigate(link);
        break;
      case 'external':
        window.open(link);
        break;
      default:
        !!onClick && onClick(e)
    }
  }

  return (
    <x.button
      onClick={(e) => handleClick(e, link)}
      p={4}
      color="white"
      bg={{ _: `btn-${variant}`, hover: `btn-${variant}-hover` }}
      borderRadius="lg"
      animation={loading ? "pulse" : null}
      fontWeight="500"
      {...rest}>
      {loading ? loadingText : children}
    </x.button>
  );
};

export default Button;
