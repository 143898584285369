import React from 'react';
import { x } from '@xstyled/styled-components';

const BurgerLine = ({ ...rest }) => {
    return (
        <x.div
            w="100%"
            h="0.25rem"
            bg="btn-primary"
            borderRadius="0.5rem"
            display="block"
            transition="all 0.3s ease"
            {...rest}
        />
    )
}

const Hamburger = ({ active, onClick }) => {
    return (
        <x.button bg="transparent" onClick={onClick}>
            <x.div
                w={"2rem"}
                h={"2rem"}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-between"}
                alignItems={"center"}
                cursor={"pointer"}
            >
                <BurgerLine transform={active ? "rotate(45deg) translate(0.25rem, 1rem) " : ""} />
                <BurgerLine opacity={active ? 0 : 1} />
                <BurgerLine transform={active ? "rotate(-45deg)  translate(0.25rem, -1rem)" : ""} />
            </x.div>
        </x.button>
    )
};

export default Hamburger;