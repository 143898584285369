import React from "react";
import SvgIcon from "../core/SvgIcon";
import { useDown } from "../../helpers/Media";

const Logo = () => {
	const isSmallScreen = useDown()

	return (
		<SvgIcon
			src="logo.svg"
			aria-label="homepage"
			width={isSmallScreen ? "50%" : "80%" }
			height={isSmallScreen ? "50%" : "80%" }
		/>
	);
}

export default Logo;